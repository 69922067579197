/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductService from "../../API/Product";
import Loader from "../../Common/Loader";
import { NotificationWithIcon } from "../../Utils/helper";
import * as Constants from "../../Utils/constants";
import { Button, Select } from "antd";
import LoadImage from "../../Common/LoadImage";
import {getReadOnlyProductUrl } from "../../Utils/helper";

interface Props {
  categoryId: any;
}

const ReadOnlyProduct: FC<Props> = ({ categoryId }: Props) => {
  const [products, setProducts] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [page, setPage] = useState<number>(1);
  const [showMoreSelected, setShowMoreSelected] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>(
    Constants.READ_ONLY_PRODUCT_FILTER[0].value
  );
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-redeclare
  const routeId = useParams();
  
  
  const getReadOnlyList = async (pageNumber: number, filter: string) => {
    setLoader(true);
    console.log("29",categoryId);
    console.log("30",routeId);
    // if(categoryId === undefined){
    //   categoryId = routeId?.id
    // }
  console.log("2999");

    let queryParams = `start=${pageNumber}&limit=${Constants.PAGESIZE}`;
    if (filter) {
      queryParams += `&${filter}`;
    }
    await ProductService.getReadOnlyList(Number(categoryId === undefined ? routeId?.id : categoryId), queryParams)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          setProducts(res?.data);
        }
        let arr: any = [];
        if (showMoreSelected) {
          arr = [...products, ...res.data];
        } else {
          setProducts([]);
          arr = [...res.data];
        }
        setProducts(arr);
        setShowMoreSelected(false);
        setTotalCount(res?.meta?.total);
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon("error", err?.data?.error?.message);
      });
  };

  useEffect(() => {
    async function getCategory() {
      await getReadOnlyList(page, selectedFilter);
    }
    getCategory();
  }, [categoryId]);

  useEffect(() => {
    if (products?.length < totalCount) {
      setShowLoadMore(true);
    }
    if (products?.length === totalCount) {
      setShowLoadMore(false);
    }
  }, [products]);

  const handleLoadMore = async () => {
    setShowMoreSelected(true);
    setPage((p) => p + 1);
  };

  useEffect(() => {
    if (page > 1) {
      getReadOnlyList(page, selectedFilter);
    }
  }, [page]);

  const handleFilter = async (value: string) => {
    setSelectedFilter(value);
    setPage(1);
    await getReadOnlyList(1, value);
  };

  return (
    <>
      {loader && <Loader />}
      <div className="container pb-5 readonly_category">
        <div className="row mb-3">
          <div className="col-md-6">
            <Select
              className="ms-auto"
              popupClassName="custom--select"
              placeholder="--Select--"
              options={Constants.READ_ONLY_PRODUCT_FILTER}
              onChange={(e) => handleFilter(e)}
              value={selectedFilter}
              style={{ width: "37%" }}
            />
          </div>
        </div>
        <div className="row mt-2">
          {products?.length > 0 &&
            products?.map((item: any) => {
              return (
                <>
                  <div className="col-md-4 mb-4 d-flex">
                    <div className="card custom-card product-card">
                      <div className="card-body">
                        <LoadImage
                          src={item?.images?.url}
                          inlineClass="img-fluid img-cursor"
                          onClick={() =>
                            navigate(`/readonly-category/${getReadOnlyProductUrl(item?.id,item?.name)}`)
                          }
                        />
                      </div>
                      <div className="card-footer">
                        <Link
                          to={{
                            pathname: `/readonly-category/${getReadOnlyProductUrl(item?.id,item?.name)}`,
                          }}
                        >
                          {item?.name}
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          {products?.length === 0 && (
            <div>
              <h3>No Products found</h3>
            </div>
          )}
        </div>
        {showLoadMore && (
          <div className="d-flex mt-4 justify-content-center">
            <Button
              className="btn btn-borderd--primary h-auto"
              onClick={handleLoadMore}
            >
              View More
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ReadOnlyProduct;
