import { AxiosResponse } from "axios";
import Service from "../../Lib/service";

const authorizationKey: any = process.env.REACT_APP_AUTHORIZATION_KEY;

const ProductService = {
  getCategoryList: async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`categories?populate[images][fields]=url`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getCategoryList error:",error);
      throw error;
    }
  },
  getProductList: async (
    categoryId: number,
    url: string
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`productsList/${categoryId}?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getProductList error:",error);
      throw error;
    }
  },
  getProductDetails: async (
    productId: number,
    url: string
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`products/${productId}?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getProductDetails error:",error);
      throw error;
    }
  },
  sendProductReview: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `product-reviews`, data },
        {
          authorization: `Bearer ${authorizationKey}`,
        }
      );
    } catch (error) {
      console.log("sendProductReview error:",error);
      throw error;
    }
  },
  getServiceManualCategory: async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`resource-categories?populate=*&all=true`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getServiceManualCategory error:",error);
      throw error;
    }
  },
  getServiceManualDocs: async (
    id: any,
    url?: string
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`serviceList/${id}?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getServiceManualDocs error:",error);
      throw error;
    }
  },
  sendRequestForQuote: async (
    data: any
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `request-a-quotes`, data },
        {
          authorization: `Bearer ${authorizationKey}`,
        }
      );
    } catch (error) {
      console.log("sendRequestForQuote error:",error);
      throw error;
    }
  },
  getProductReviewList: async (
    id: any,
    url: any
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`reiviewList/${id}?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getProductReviewList error:",error);
      throw error;
    }
  },
  getReadOnlyCategory: async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`read-only-categories?all=true&populate[images][fields]=url&populate=seo`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getReadOnlyCategory error:",error);
      throw error;
    }
  },
  getReadOnlyList: async (
    categoryId: number,
    url: string
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`readOnlyProductList/${categoryId}?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getReadOnlyList error:",error);
      throw error;
    }
  },
  getProductDetail: async (
    id: number,
    url: string
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`read-only-products/${id}?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getProductDetail error:",error);
      throw error;
    }
  },
  getAllReadOnlyProducts: async (
    url: string
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`read-only-products?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getAllReadOnlyProducts error:",error);
      throw error;
    }
  },
  getAllReadOnlyProductCategories: async (
    url: string
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`read-only-product-categories?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getAllReadOnlyProductCategories error:",error);
      throw error;
    }
  },
};

export default ProductService;
