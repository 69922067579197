import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ROUTES from "../../routes";
import Breadcrumb from "../../Common/Breadcrumb";
import { Carousel } from "react-bootstrap";
import { geturl } from "../../Utils/helper";
import ReadOnlyProduct from "../ReadOnlyProduct";
import LoadImage from "../../Common/LoadImage";
import { Helmet } from "react-helmet-async";
// import { slugify } from "../../Utils/helper";
import ProductService from "../../API/Product";



const ReadOnlyCategory: FC = () => {
  console.log("11",ReadOnlyCategory);
  
  const [categoryData, setCategoryData] = useState<any>("");
  const [seoData, setSeoData] = useState<any>();
    const routeId = useParams();

  // const { state } = useLocation();

  // useEffect(() => {
  //   console.log("18",state.category);
  //   window.scrollTo(0, 0);
  //   async function getReadOnlyCategory() {
  //       let longDec = state?.category.description;
  //       setSeoData(state?.category.seo)
  //       const el = document.createElement("div");
  //       el.innerHTML = longDec;
  //       el.querySelectorAll("img").forEach((imgEl) => {
  //         const srcAtt = imgEl.getAttribute("src");
  //         if (srcAtt) {
  //           const srcOriginal = geturl(srcAtt);
  //           imgEl.src = srcOriginal;
  //         }
  //       });
  //       longDec = el.innerHTML;
  //       const category = state?.category;
  //       setCategoryData({ ...category, description: longDec });
  //     }
  //   getReadOnlyCategory();
  // }, [state]);

  useEffect(() => {
    async function getnewCategory() {
        const response = await ProductService.getReadOnlyCategory();
            if(response) {
              console.log("log",response.data);
              const data: any = response?.data;
              const categorySelected: any = data?.filter((ele: any) => ele.slug === routeId.name);
              console.log("categorySelected", categorySelected[0]);
              let longDec = categorySelected[0].description;
        setSeoData(categorySelected[0].seo)
        const el = document.createElement("div");
        el.innerHTML = longDec;
        el.querySelectorAll("img").forEach((imgEl) => {
          const srcAtt = imgEl.getAttribute("src");
          if (srcAtt) {
            const srcOriginal = geturl(srcAtt);
            imgEl.src = srcOriginal;
          }
        });
        longDec = el.innerHTML;
        const category = categorySelected[0];
        setCategoryData({ ...category, description: longDec });
            }
    }
    getnewCategory();
  }, [routeId])

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: categoryData?.name,
      path: "#",
    },
  ];      
  return (
    <>
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle={categoryData?.name} />
      {seoData && (
      <Helmet>
      <title>{seoData?.metaTitle}</title>
      <meta property="og:title" content={seoData?.metaTitle} />
      <meta property="og:description" content={seoData?.metaDescription} />
      <meta property="og:image" content={seoData?.canonicalURL} />
      <meta property="keywords" content={seoData?.keywords} />
      <link rel="canonical" href={seoData.canonicalUrl || window.location.origin + window.location.pathname} />
  </Helmet>
  )}
      <div className="container pb-5">
        <div className="row">
          <div className="col-12">
            <h4 className="block-title-xl">{categoryData?.name}</h4>
            <div className="hr--divider"></div>
          </div>
        </div>
        <div className="row mt-3">
          {categoryData?.images?.length > 0 && (
            <div className="col-12 mb-3">
              <Carousel className="banner-slider product-img-preview-slider">
                {categoryData?.images?.map((dataItem: any, index: number) => {
                  return (
                    <Carousel.Item key={index}>
                      <LoadImage src={dataItem?.url} inlineClass="img-fluid" />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          )}
          <div className="col-12 mb-3 mt-3">
            <p
              className="cmn-txt img-width"
              dangerouslySetInnerHTML={{
                __html: categoryData?.description,
              }}
            ></p>
          </div>
          <ReadOnlyProduct categoryId={categoryData?.id} />
        </div>
      </div>
    </>
  );
};

export default ReadOnlyCategory;
