import { useEffect, useState } from "react";
import XMLViewer from "react-xml-viewer";

const Sitemap: React.FC = () => {
  const [xmlData, setXmlData] = useState<any>("");

  

  useEffect(() => {
    fetch("https://api.skyazul.com/sitemap/index.xml")
      .then((response) => response.text())
      .then((data) => setXmlData(data))
      .catch((error) => console.error("Error loading sitemap:", error));
  }, []);

  return (
    <div>
      {xmlData ? <XMLViewer xml={xmlData} /> : <p>Loading...</p>}
    </div>
  );
};

export default Sitemap;



